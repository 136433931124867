import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Zapier for Real Estate Agents: An Alternative | Real Synch"
        description="Discover the power of Real Synch. It's like Zapier but designed exclusively for real estate agents and their teams. Easy setup. No IT staff is needed."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-12 rounded-bl-[60px] bg-primary-900 pt-20 pb-20 md:mb-24 md:rounded-bl-[120px] md:pt-32 md:pb-28 lg:rounded-bl-[220px]">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-14 lg:gap-x-28">
            <div className="order-2 md:order-1">
              <h1 className="text-white">Discover the Power of Real Synch</h1>
              <p className="text-white/80 md:mb-8">
                Real Synch is like Zapier, but designed exclusively for real
                estate agents. Easily automate and streamline your team's
                workflow and data entry tasks at a fraction of the cost—no IT
                staff needed.
              </p>
              <ButtonSolid
                altStyle={2}
                modal="modal-contact"
                text="Schedule a Consult"
              />
            </div>
            <div className="order-1 text-center md:order-2 md:text-left">
              <StaticImage
                src="../images/3.0 Features/1.0 Hero.png"
                loading="eager"
                width={560}
                className="mx-auto"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-24">
        <div className="container">
          <div className="grid items-center gap-y-6 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <img
                src={data.optimizeYourPipeline.publicURL}
                alt="Optimize Your Pipeline"
              />
            </div>
            <div>
              <header className="mb-8 md:mb-10">
                <h2>Optimize Your Pipeline</h2>
              </header>
              <ul className="styled-list-checkmark mb-0 space-y-5">
                <li>
                  <div>
                    <b className="text-typography-heading">
                      Unlimited Integrations
                    </b>
                    <p className="mb-0">
                      Connect as many systems as you like to create a
                      streamlined workflow for your team.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <b className="text-typography-heading">
                      CRM & Gmail Connection
                    </b>
                    <p className="mb-0">
                      Use your CRM directly within Gmail to increase your team’s
                      productivity and lead follow-up.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <b className="text-typography-heading">Custom Setup</b>
                    <p className="mb-0">
                      Work with our team of experts to build custom integrations
                      for optimum workflow efficiency.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <section className="mb-12 rounded-4xl bg-primary-200 py-20 px-6 md:mb-24 md:px-10 lg:px-20">
          <header className="mb-16 text-center md:mb-20">
            <h2>Get Set Up For Success</h2>
          </header>
          <div className="grid gap-y-8 text-center md:grid-cols-3 md:gap-x-5 lg:gap-x-10">
            <div>
              <img
                src={data.success1.publicURL}
                alt="Free Strategy Call"
                className="mx-auto mb-2.5"
              />
              <div className="mb-3 text-lg font-bold text-typography-heading">
                Free Strategy Call
              </div>
              <p className="mb-0 text-typography-body/80">
                We’ll help you define the best strategy for integrating and
                automating your systems.
              </p>
            </div>
            <div>
              <img
                src={data.success2.publicURL}
                alt="Dedicated Support Agent"
                className="mx-auto mb-2.5"
              />
              <div className="mb-3 text-lg font-bold text-typography-heading">
                Dedicated Support Agent
              </div>
              <p className="mb-0 text-typography-body/80">
                Upon sign up, you’ll get dedicated support from one of our
                integration experts.
              </p>
            </div>
            <div>
              <img
                src={data.success3.publicURL}
                alt="Easy Ways To Connect With Us"
                className="mx-auto mb-2.5"
              />
              <div className="mb-3 text-lg font-bold text-typography-heading">
                Easy Ways To Connect With Us
              </div>
              <p className="mb-0 text-typography-body/80">
                Live chat with us online, join us on a Zoom call for some face
                time, or send us an email.
              </p>
            </div>
          </div>
        </section>
      </div>

      <section className="mb-12 md:mb-24">
        <div className="container">
          <div className="grid items-center gap-y-6 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="md:order-2">
              <img
                src={data.createEfficiency.publicURL}
                alt="Create Efficiency"
              />
            </div>
            <div className="md:order-1">
              <header className="mb-8 md:mb-10">
                <h2>Create Efficiency</h2>
              </header>
              <ul className="styled-list-checkmark mb-0 space-y-5">
                <li>
                  <div>
                    <b className="text-typography-heading">
                      Quick & Easy Set-Up
                    </b>
                    <p className="mb-0">
                      Integration takes less than 60 seconds—you simply select a
                      source, select a target, connect them, and activate.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <b className="text-typography-heading">Fast Data Sync</b>
                    <p className="mb-0">
                      Sync your leads, contacts, and activities in real-time or
                      within five minutes if on a polling cycle.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <b className="text-typography-heading">
                      Built-In Automations
                    </b>
                    <p className="mb-0">
                      We designed Real Synch with pre-built filters and options
                      designed to automate workflow.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <b className="text-typography-heading">
                      Tailored Solutions
                    </b>
                    <p className="mb-0">
                      We can customize and use any tool to fit your real estate
                      team’s requirements.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-12 md:mb-24">
        <div className="container">
          <div className="grid items-center gap-y-6 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <img
                src={data.integrationAutomationReports.publicURL}
                alt="Integration & Automation Reports"
              />
            </div>
            <div>
              <header className="mb-8 md:mb-10">
                <h2>Integration & Automation Reports</h2>
              </header>

              <ul className="styled-list-checkmark mb-0 space-y-5">
                <li>
                  <div>
                    <b className="text-typography-heading">
                      Usage & ROI Report
                    </b>
                    <p className="mb-0">
                      See how much time your team is saving, and the data behind
                      your integrations, in a monthly report.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <b className="text-typography-heading">CRM Audit Report</b>
                    <p className="mb-0">
                      Get a custom CRM audit and report outlining how Real Sync
                      integrations can automate your pipeline.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    optimizeYourPipeline: file(
      relativePath: { eq: "3.0 Features/2. Optimize Your Pipeline.svg" }
    ) {
      publicURL
    }
    createEfficiency: file(
      relativePath: { eq: "3.0 Features/4.0 Create Efficiency.svg" }
    ) {
      publicURL
    }
    integrationAutomationReports: file(
      relativePath: {
        eq: "3.0 Features/5.0 Integration _ Automation Reports.svg"
      }
    ) {
      publicURL
    }
    success1: file(
      relativePath: { eq: "3.0 Features/3.0 Free Strategy Call.svg" }
    ) {
      publicURL
    }
    success2: file(
      relativePath: { eq: "3.0 Features/3.1 Dedicated Support Agent.svg" }
    ) {
      publicURL
    }
    success3: file(
      relativePath: { eq: "3.0 Features/3.2 Easy Ways To Connect With Us.svg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
